import "./App.css";

const companyInfo = {
  name: "Dou Tech LLC",
  contactEmail: "contact@dou-technology.com",
  address: "30 N Gould St Ste R, Sheridan, WY 82801",
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Company-info">
          <h2>{companyInfo.name}</h2>
          <p>
            Contact us at:{" "}
            <a href={`mailto:${companyInfo.contactEmail}`}>
              {companyInfo.contactEmail}
            </a>
          </p>
          <p>Address: {companyInfo.address}</p>
        </div>
      </header>
    </div>
  );
}

export default App;
